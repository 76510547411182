import { render, staticRenderFns } from "./SampleTypes.vue?vue&type=template&id=c44c5eb6&scoped=true&"
import script from "./SampleTypes.vue?vue&type=script&lang=js&"
export * from "./SampleTypes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c44c5eb6",
  null
  
)

export default component.exports