


<template>
  <div id="laboratories">
    <b-container fluid>
      <!-- FABs -->
      <RefreshFAB :callback="getData" />
      <download-excel :data="laboratories" type="csv" name="Laboratories.csv">
        <ExportFAB icon="csv" />
      </download-excel>
      <download-excel :data="laboratories" type="xls" name="Laboratories.xls">
        <ExportFAB class="down-fab" icon="xls" />
      </download-excel>
      <AddFAB class="add-fab" link="/new-laboratory" />
      <b-row>
        <Title :title="$t('title_laboratories')" class="col-12" />

        <vue-good-table
          :columns="columns"
          :rows="laboratories"
          class="col-12"
          compactMode
          :search-options="tableSearch()"
          :pagination-options="tablePagination()"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'open_button'">
              <router-link
                :to="'/laboratory/' + props.formattedRow['laboratory_id']"
                class="open-button"
              >
                <img
                  :alt="$t('alt_open_button')"
                  src="../assets/icons/open.svg"
                />
              </router-link>
            </span>
            <span v-else> {{ props.formattedRow[props.column.field] }} </span>
          </template>
        </vue-good-table>
      </b-row>
    </b-container>
  </div>
</template>


<script>
import RefreshFAB from "../components/RefreshFAB.vue";
import ExportFAB from "../components/ExportFAB.vue";
import Title from "../components/Title.vue";
import AddFAB from "../components/AddFAB.vue";

export default {
  name: "Laboratories",
  components: {
    Title,
    RefreshFAB,
    ExportFAB,
    AddFAB,
  },
  data: function () {
    return {
      laboratories: [],
      columns: [
        {
          label: this.$t("table_laboratory_id"),
          field: "laboratory_id",
          type: "text",
          tdClass: "align-middle",
        },
        {
          label: this.$t("table_laboratory_name"),
          field: "laboratory_name",
          type: "text",
          tdClass: "align-middle",
        },
        {
          field: "open_button",
          globalSearchDisabled: true,
          html: true,
          sortable: false,
          tdClass: "text-center",
        },
      ],
    };
  },
  mounted() {
    this.setTitle(this.$t("title_laboratories"));
    this.getData();
  },
  methods: {
    getData() {
      this.$Progress.start();
      this.axios(
        this.buildRequest(this.REQUESTS.GET, "/get-laboratories-admin")
      )
        .then((response) => {
          this.laboratories = response.data;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errorHandler(error, this.getData);
          this.$Progress.fail();
        });
    },
  },
};
</script>


<style scoped>
</style>