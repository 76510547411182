var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ hide: _vm.hide },attrs:{"id":"side-menu"}},[_c('NavFAB',{attrs:{"hide":_vm.hide,"hideEvent":_vm.hideEvent}}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('h1',{staticClass:"col-12"},[_vm._v(_vm._s(_vm.$t("_brand")))]),_c('hr'),_c('router-link',{staticClass:"col-12 menu-item",attrs:{"to":"/"},nativeOn:{"click":function($event){return _vm.hideEvent()}}},[_vm._v(_vm._s(_vm.$t("nav_home"))+" ")]),_vm._l(([
          'orders',
          'activekits',
          'products',
          'biomarkers',
          'laboratories',
          'categories',
          'sampletypes',
          'users',
          'discounts',
          'videos',
          'bannersettings'
        ]),function(navItem){return _c('router-link',{key:navItem,staticClass:"col-12 menu-item",attrs:{"to":'/' + navItem},nativeOn:{"click":function($event){return _vm.hideEvent()}}},[_vm._v(_vm._s(_vm.$t("nav_" + navItem))+" ")])}),_c('div',{staticClass:"col-6 pt-5 text-right",on:{"click":function($event){return _vm.switchLanguage('fr')}}},[_c('gb-flag',{staticClass:"col-6 p-0 clickable hover-anim",attrs:{"code":"fr","size":"small"}})],1),_c('div',{staticClass:"col-6 pt-5",on:{"click":function($event){return _vm.switchLanguage('en')}}},[_c('gb-flag',{staticClass:"col-6 p-0 clickable hover-anim",attrs:{"code":"gb","size":"small"}})],1),_c('a',{staticClass:"col-12 menu-item text-center clickable pt-3",on:{"click":function($event){_vm.logout();
          _vm.hideEvent();}}},[_vm._v(_vm._s(_vm.$t("nav_logout"))+" ")])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }